<template>
  <div class="bg-color-white rounded-box overflow-hidden relative">
    <div class="detail-store-info-close-btn" @click="deHighlightStore()">
      <svg class="absolute" style="top: 50%; left: 50%; transform: translate(-50%, -50%)" width="13" height="13"
        viewBox="0 0 13 13" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M1.27199 0.91419C0.877566 1.30968 0.877565 1.95091 1.27199 2.3464L5.55697 6.64297L1.27195 10.9396C0.877525 11.3351 0.877525 11.9763 1.27195 12.3718C1.66638 12.7673 2.30587 12.7673 2.7003 12.3718L6.98532 8.07517L11.2704 12.3719C11.6649 12.7674 12.3043 12.7674 12.6988 12.3719C13.0932 11.9764 13.0932 11.3352 12.6988 10.9397L8.41367 6.64297L12.6987 2.34631C13.0932 1.95082 13.0932 1.3096 12.6987 0.914104C12.3043 0.518611 11.6648 0.518611 11.2704 0.914105L6.98532 5.21076L2.70034 0.914189C2.30591 0.518695 1.66642 0.518696 1.27199 0.91419Z"
          fill="#AFB3B9" />
      </svg>
    </div>

    <img v-if="showStoreImage" class="col-12 cover"
      :src="require('../assets/images/storefinder_listing_placeholder.jpg')" />
    <div v-if="showAdditionalImages" class="flex items-center gap1 overflow-x-scroll no-scrollbar p1">
      <div style="
          height: 100px;
          width: 100px;
          background-color: blue;
          flex-shrink: 0;
          background-color: #d9d9d9;
          border-radius: 8px;
        "></div>
      <div style="
          height: 100px;
          width: 100px;
          background-color: blue;
          flex-shrink: 0;
          background-color: #d9d9d9;
          border-radius: 8px;
        "></div>
      <div style="
          height: 100px;
          width: 100px;
          background-color: blue;
          flex-shrink: 0;
          background-color: #d9d9d9;
          border-radius: 8px;
        "></div>
      <div style="
          height: 100px;
          width: 100px;
          background-color: blue;
          flex-shrink: 0;
          background-color: #d9d9d9;
          border-radius: 8px;
        "></div>
      <div style="
          height: 100px;
          width: 100px;
          background-color: blue;
          flex-shrink: 0;
          background-color: #d9d9d9;
          border-radius: 8px;
        "></div>
      <div style="
          height: 100px;
          width: 100px;
          background-color: blue;
          flex-shrink: 0;
          background-color: #d9d9d9;
          border-radius: 8px;
        "></div>
    </div>
    <div v-if="showActionButtons" class="col-12 h1px bg-color-12"></div>
    <div v-if="showBigName" class="flex gap1 items-center p1 border-box">
      <StoreInfoStoreLogo :store="store" />
      <p class="open-sans-bold h5">{{ store.name }}</p>
    </div>
    <div class="flex gap1 items-center p1 border-box" v-if="!showBigName">
      <StoreInfoStoreLogo :store="store" />
      <div>
        <p class="open-sans-bold h5">{{ store.name }}</p>
        <div class="flex items-center gap1 overflow-x-scroll no-scrollbar">
          <StoreInfoOpeningTimesSubline v-if="showOpeningTimesSubline" :store="store" />
          <StoreInfoAddressSubline v-if="showAddressSubline" :store="store" />
          <StoreInfoDistance v-if="showDistanceSubline" :position="position" :userPosition="userPosition" />
        </div>
      </div>
    </div>

    <!-- Action Buttons -->
    <div v-if="showActionButtons" class="col-12 h1px bg-color-12"></div>
    <div v-if="showActionButtons" class="p2 overflow-x no-scrollbar flex gap1">
      <div @click="openContactModal()" class="py1 px2 flex gap1 bg-color-1 items-center pointer"
        style="border-radius: 999px">
        <svg width="15" height="12" viewBox="0 0 15 12" fill="none">
          <path
            d="M12.3655 0.150635H1.7665C1.29815 0.151129 0.849121 0.334702 0.517948 0.661077C0.186774 0.987451 0.000500999 1.42997 0 1.89153V9.35252C0.000500999 9.81408 0.186774 10.2566 0.517948 10.583C0.849121 10.9093 1.29815 11.0929 1.7665 11.0934H12.3655C12.8338 11.0929 13.2828 10.9093 13.614 10.583C13.9452 10.2566 14.1315 9.81408 14.132 9.35252V1.89153C14.1315 1.42997 13.9452 0.987451 13.614 0.661077C13.2828 0.334702 12.8338 0.151129 12.3655 0.150635ZM11.9182 3.03026L7.37575 6.51206C7.28717 6.57992 7.17818 6.61676 7.06598 6.61676C6.95379 6.61676 6.84479 6.57992 6.75621 6.51206L2.2138 3.03026C2.16043 2.99055 2.1156 2.94077 2.08192 2.88383C2.04824 2.82689 2.02638 2.76392 2.01761 2.69858C2.00883 2.63324 2.01332 2.56684 2.03082 2.50322C2.04831 2.43961 2.07846 2.38006 2.11951 2.32802C2.16056 2.27599 2.21169 2.23252 2.26994 2.20013C2.32819 2.16775 2.39238 2.14709 2.4588 2.13936C2.52522 2.13164 2.59253 2.137 2.65682 2.15513C2.72112 2.17327 2.78112 2.20381 2.83333 2.245L7.06598 5.48928L11.2986 2.245C11.4045 2.16623 11.5375 2.13168 11.669 2.14883C11.8005 2.16597 11.9198 2.23343 12.0012 2.33662C12.0826 2.4398 12.1196 2.57042 12.104 2.70023C12.0885 2.83003 12.0217 2.94859 11.9182 3.03026Z"
            fill="white" />
        </svg>
        <p class="color-white open-sans-regular h6">
          {{ $t("storefinder_store_contact") }}
        </p>
      </div>

      <a v-if="store.dealerDetails.websiteURL != ''" :href="'https://' + store.dealerDetails.websiteURL" target="_blank"
        rel="norefferer noopener" class="py1 px2 flex gap2 bg-color-1 items-center" style="border-radius: 999px">
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
          <path
            d="M6.45276 0.30127C9.94374 0.30127 12.7736 3.13109 12.7736 6.62207C12.7736 10.113 9.94374 12.9429 6.45276 12.9429C2.96178 12.9429 0.131958 10.113 0.131958 6.62207C0.131958 3.13109 2.96178 0.30127 6.45276 0.30127ZM8.98361 7.17704C8.76554 6.84519 8.60815 6.60627 8.00894 6.70108C6.87752 6.88059 6.75173 7.07843 6.69801 7.40459L6.68284 7.50319L6.66703 7.60812C6.60572 8.03983 6.60762 8.2029 6.80609 8.41086C7.60567 9.25026 8.08479 9.85516 8.23017 10.2091C8.30096 10.3817 8.483 10.9044 8.35785 11.4215C9.12508 11.1159 9.80801 10.631 10.3495 10.0075C10.4191 9.77109 10.4696 9.47654 10.4696 9.12005V9.05368C10.4696 8.4709 10.4696 8.2048 10.0575 7.96903C9.92506 7.89392 9.789 7.82536 9.64982 7.76361C9.41785 7.65805 9.26425 7.58915 9.05693 7.28575C9.03191 7.24991 9.00747 7.21367 8.98361 7.17704ZM6.45276 1.45987C4.98823 1.45987 3.66529 2.07046 2.72601 3.05019C2.83789 3.12793 2.93523 3.23728 3.00223 3.38772C3.13118 3.67658 3.13118 3.97429 3.13118 4.23786C3.13118 4.44519 3.13118 4.6424 3.19755 4.78461C3.28857 4.97929 3.68172 5.06273 4.02873 5.13479C4.15325 5.16133 4.28093 5.18788 4.39723 5.22012C4.71707 5.30861 4.96484 5.5962 5.16268 5.82691C5.24485 5.92236 5.36685 6.06331 5.42816 6.09871C5.45976 6.07595 5.56153 5.96534 5.61146 5.78393C5.65065 5.64487 5.63927 5.52225 5.58302 5.45525C5.22905 5.03808 5.24865 4.23534 5.358 3.93889C5.52992 3.47178 6.06719 3.50655 6.46034 3.53183C6.60699 3.54131 6.74478 3.55079 6.84844 3.53752C7.24159 3.48822 7.36295 2.88964 7.44828 2.7727C7.63285 2.51987 8.19793 2.13873 8.5481 1.90359C7.88853 1.6103 7.1746 1.45912 6.45276 1.45987Z"
            fill="white" />
        </svg>
        <p class="color-white open-sans-regular h6">
          {{ $t("storefinder_store_website") }}
        </p>
      </a>

      <a v-if="store.dealerDetails.phoneNumber != ''" :href="`tel:` + store.dealerDetails.phoneNumber"
        class="py1 px2 flex gap2 bg-color-1 items-center" style="border-radius: 999px">
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none">
          <path
            d="M2.903 4.9103C3.75007 6.57502 5.11479 7.93387 6.77952 8.78682L8.07366 7.49268C8.23248 7.33386 8.46778 7.28092 8.67367 7.3515C9.3325 7.56915 10.0443 7.6868 10.7737 7.6868C11.0972 7.6868 11.3619 7.95151 11.3619 8.27505V10.328C11.3619 10.6516 11.0972 10.9163 10.7737 10.9163C5.25009 10.9163 0.77356 6.43973 0.77356 0.916124C0.77356 0.59259 1.03827 0.327881 1.3618 0.327881H3.42065C3.74419 0.327881 4.0089 0.59259 4.0089 0.916124C4.0089 1.65143 4.12655 2.35732 4.3442 3.01615C4.4089 3.22204 4.36184 3.45145 4.19713 3.61616L2.903 4.9103Z"
            fill="white" />
        </svg>
        <p class="color-white open-sans-regular h6">
          {{ $t("storefinder_store_phone") }}
        </p>
      </a>
    </div>

    <!-- Address -->
    <div v-if="
      showAddress &&
      store.dealerDetails.address1 != '' &&
      store.dealerDetails.city != '' &&
      store.dealerDetails.postal != ''
    " class="col-12 h1px bg-color-12"></div>
    <div v-if="
      showAddress &&
      store.dealerDetails.address1 != '' &&
      store.dealerDetails.city != '' &&
      store.dealerDetails.postal != ''
    ">
      <div class="overflow-scroll no-scrollbar flex gap2 items-center p2">
        <svg class="no-shrink" width="12" height="19" viewBox="0 0 12 19" fill="none">
          <path
            d="M11.7772 6.4645C11.7772 9.71668 5.88858 18.6683 5.88858 18.6683C5.88858 18.6683 0 9.71668 0 6.4645C0 3.21233 2.63641 0.575928 5.88858 0.575928C9.14075 0.575928 11.7772 3.21233 11.7772 6.4645Z"
            fill="#1867FF" />
          <circle cx="5.88891" cy="6.20019" r="1.90576" fill="white" />
        </svg>
        <p class="h5 color-14 open-sans-regular no-shrink">
          {{ store.dealerDetails.address1 }}
        </p>
        <div class="bg-color-12 no-shrink" style="width: 1px; height: 16px"></div>
        <p class="h5 color-14 open-sans-regula no-shrink">
          {{ store.dealerDetails.postal }} {{ store.dealerDetails.city }}
        </p>
        <div class="bg-color-12 no-shrink" style="width: 1px; height: 16px"></div>
        <p class="h5 color-14 open-sans-regular no-shrink">
          {{ distance }} {{ $t("storefinder_unit_kilometers") }}
          {{ $t("storefinder_store_away") }}
        </p>
      </div>
    </div>

    <!-- Opening times -->
    <div v-if="showOpeningTimes && store.dealerDetails.openingTimes != null" class="col-12 h1px bg-color-12"></div>
    <div v-if="showOpeningTimes && store.dealerDetails.openingTimes != null" class="p2 overflow-x no-scrollbar">
      <div class="flex gap2 items-center pointer" @click="showTimes = !showTimes">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
          <path
            d="M9.3252 17.0822C11.3038 17.0822 13.2013 16.2962 14.6003 14.8972C15.9994 13.4981 16.7854 11.6006 16.7854 9.62207C16.7854 7.64351 15.9994 5.74599 14.6003 4.34694C13.2013 2.94789 11.3038 2.16191 9.3252 2.16191C7.34664 2.16191 5.44912 2.94789 4.05007 4.34694C2.65102 5.74599 1.86504 7.64351 1.86504 9.62207C1.86504 11.6006 2.65102 13.4981 4.05007 14.8972C5.44912 16.2962 7.34664 17.0822 9.3252 17.0822ZM9.3252 0.296875C10.5498 0.296875 11.7624 0.538078 12.8938 1.00671C14.0252 1.47535 15.0532 2.16224 15.9191 3.02816C16.785 3.89409 17.4719 4.92209 17.9406 6.05347C18.4092 7.18486 18.6504 8.39747 18.6504 9.62207C18.6504 12.0953 17.6679 14.4672 15.9191 16.216C14.1703 17.9648 11.7984 18.9473 9.3252 18.9473C4.16836 18.9473 0 14.7509 0 9.62207C0 7.14887 0.982473 4.77698 2.73129 3.02816C4.4801 1.27935 6.852 0.296875 9.3252 0.296875ZM9.79146 4.95947V9.8552L13.9878 12.345L13.2884 13.492L8.39268 10.5546V4.95947H9.79146Z"
            fill="#1867FF" />
        </svg>
        <p class="h5 color-3 open-sans-regular">Open</p>
        <div class="bg-color-12" style="width: 1px; height: 16px"></div>
        <p class="h5 color-14 open-sans-regular">Closes at 6pm</p>
        <svg class="ml-auto" :style="`${showTimes ? 'transform: rotate(180deg)' : ''}`" width="12" height="7"
          viewBox="0 0 12 7" fill="none">
          <path d="M0.751465 1.05994L5.87573 6.1842L11 1.05994" stroke="black" />
        </svg>
      </div>
      <!-- Times accordion -->
      <div v-show="showTimes">
        <div class="pt2 grid grid-2-col gap1" style="margin-left: 35px">
          <p class="h5 color-14 open-sans-regular">Monday:</p>
          <p class="h5 color-14 open-sans-regular">12:00 - 18:00</p>
          <p class="h5 color-14 open-sans-regular">Tuesday:</p>
          <p class="h5 color-14 open-sans-regular">12:00 - 18:00</p>
          <p class="h5 color-14 open-sans-regular">Wednesday:</p>
          <p class="h5 color-14 open-sans-regular">12:00 - 18:00</p>
          <p class="h5 color-14 open-sans-regular">Thursday:</p>
          <p class="h5 color-14 open-sans-regular">12:00 - 18:00</p>
          <p class="h5 color-14 open-sans-regular">Friday:</p>
          <p class="h5 color-14 open-sans-regular">12:00 - 18:00</p>
          <p class="h5 color-14 open-sans-regular">Saturday:</p>
          <p class="h5 color-14 open-sans-regular">12:00 - 18:00</p>
          <p class="h5 color-14 open-sans-regular">Sunday:</p>
          <p class="h5 color-14 open-sans-regular">12:00 - 18:00</p>
        </div>
      </div>
    </div>

    <!-- Products -->
    <div v-if="showProductTags && store.dealerDetails.productsTags != null" class="col-12 h1px bg-color-12"></div>
    <div v-if="showProductTags && store.dealerDetails.productsTags != null">
      <p class="h5 color-14 open-sans-regular mb1 pt2 px2">
        Special Red Sea products
      </p>
      <div class="overflow-x no-scrollbar pb2 px2 border-box flex gap2 items-center">
        <div class="py1 px2 flex gap1 items-center border color-12 w-fit-content no-shrink"
          style="border-radius: 999px">
          <svg width="12" height="9" viewBox="0 0 12 9" fill="none">
            <path d="M1 3.40353L4.75838 7.16194L10.8382 1.08215" stroke="#1867FF" stroke-width="2" />
          </svg>
          <p class="color-black open-sans-regular h6">Algae Chemicals</p>
        </div>

        <div class="py1 px2 flex gap1 items-center border color-12 w-fit-content no-shrink"
          style="border-radius: 999px">
          <svg width="12" height="9" viewBox="0 0 12 9" fill="none">
            <path d="M1 3.40353L4.75838 7.16194L10.8382 1.08215" stroke="#1867FF" stroke-width="2" />
          </svg>
          <p class="color-black open-sans-regular h6">Algae Chemicals</p>
        </div>

        <div class="py1 px2 flex gap1 items-center border color-12 w-fit-content no-shrink"
          style="border-radius: 999px">
          <svg width="12" height="9" viewBox="0 0 12 9" fill="none">
            <path d="M1 3.40353L4.75838 7.16194L10.8382 1.08215" stroke="#1867FF" stroke-width="2" />
          </svg>
          <p class="color-black open-sans-regular h6">Algae Chemicals</p>
        </div>
      </div>
    </div>

    <!-- Streetview Button -->
    <div v-if="showStreetViewButton" class="col-12 h1px bg-color-12"></div>
    <div v-if="showStreetViewButton && !isStreetView" class="flex gap1 p2 overflow-x no-scrollbar">
      <a target="_blank"
        :href="`https://www.google.com/maps/dir/?api=1&origin=${userPosition.lat}%2C${userPosition.lng}&destination=${position.lat}%2C${position.lng}&travelmode=car`"
        class="py1 px2 flex gap1 bg-color-1 items-center" style="border-radius: 999px">
        <svg width="13" height="19" viewBox="0 0 13 19" fill="none">
          <path
            d="M12.3772 6.0906C12.3772 9.34277 6.48864 18.2944 6.48864 18.2944C6.48864 18.2944 0.600067 9.34277 0.600067 6.0906C0.600067 2.83843 3.23647 0.202026 6.48864 0.202026C9.74081 0.202026 12.3772 2.83843 12.3772 6.0906Z"
            fill="white" />
          <circle cx="6.48901" cy="5.82629" r="1.90576" fill="#1867FF" />
        </svg>
        <p class="color-white open-sans-regular h6">
          {{ $t("storefinder_store_route") }}
        </p>
      </a>
      <div class="py1 px2 flex gap1 bg-color-1 items-center w-fit-content" style="border-radius: 999px">
        <div @click="addStreetView()" class="color-white open-sans-regular h6 pointer flex items-center gap1">
          <svg class="svg-color-white" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <path
              d="M5 2.5C5 1.83696 5.26339 1.20107 5.73223 0.732233C6.20107 0.263392 6.83696 0 7.5 0C8.16304 0 8.79893 0.263392 9.26777 0.732233C9.73661 1.20107 10 1.83696 10 2.5C10 3.16304 9.73661 3.79893 9.26777 4.26777C8.79893 4.73661 8.16304 5 7.5 5C6.83696 5 6.20107 4.73661 5.73223 4.26777C5.26339 3.79893 5 3.16304 5 2.5ZM7.5 6C6.57174 6 5.6815 6.36875 5.02513 7.02513C4.36875 7.6815 4 8.57174 4 9.5V11.5C4 11.6326 4.05268 11.7598 4.14645 11.8536C4.24021 11.9473 4.36739 12 4.5 12H10.5C10.6326 12 10.7598 11.9473 10.8536 11.8536C10.9473 11.7598 11 11.6326 11 11.5V9.5C11 8.57174 10.6313 7.6815 9.97487 7.02513C9.3185 6.36875 8.42826 6 7.5 6Z"
              fill="black" />
            <path
              d="M1.394 10.6949C1.111 10.9869 1 11.2579 1 11.4999C1 11.7449 1.114 12.0199 1.406 12.3159C1.7 12.6149 2.151 12.9059 2.747 13.1619C3.938 13.6719 5.618 13.9999 7.5 13.9999C9.382 13.9999 11.062 13.6719 12.253 13.1619C12.849 12.9059 13.3 12.6149 13.594 12.3159C13.886 12.0199 14 11.7459 14 11.4999C14 11.2579 13.89 10.9869 13.605 10.6949C13.32 10.4009 12.881 10.1129 12.3 9.85789L12.7 8.94189C13.356 9.22889 13.918 9.58189 14.322 9.99789C14.729 10.4159 15 10.9249 15 11.4999C15 12.0829 14.722 12.5969 14.306 13.0189C13.892 13.4389 13.317 13.7929 12.646 14.0809C11.304 14.6559 9.484 14.9999 7.5 14.9999C5.516 14.9999 3.696 14.6559 2.353 14.0799C1.683 13.7929 1.108 13.4379 0.694 13.0189C0.278 12.5969 0 12.0829 0 11.4989C0 10.9239 0.27 10.4159 0.678 9.99789C1.082 9.58189 1.644 9.22889 2.3 8.94189L2.7 9.85789C2.119 10.1129 1.68 10.4009 1.394 10.6949Z"
              fill="black" />
          </svg>
          <span>Go to Streeview</span>
        </div>
      </div>
    </div>

    <div v-if="showStreetViewButton && isStreetView" class="p2 overflow-x no-scrollbar">
      <div class="py1 px2 flex gap1 bg-color-1 items-center w-fit-content" style="border-radius: 999px">
        <div @click="esacpeStreetView()" class="color-white open-sans-regular h6 pointer flex items-center gap1">
          <svg width="12" height="12" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_1922_48204)">
              <rect width="19.6867" height="2" rx="1"
                transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.15625 1.58594)" fill="white" />
              <rect x="15.4902" y="1.58594" width="19.6867" height="2" rx="1" transform="rotate(135 15.4902 1.58594)"
                fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_1922_48204">
                <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 1 16 0)" />
              </clipPath>
            </defs>
          </svg>
          <span>Exit Streetview</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import StoreInfoStoreLogo from "./StoreInfoStoreLogo.vue";
import StoreInfoDistance from "./StoreInfoDistance.vue";
import StoreInfoAddressSubline from "./StoreInfoAddressSubline.vue";
import StoreInfoOpeningTimesSubline from "./StoreInfoOpeningTimesSubline.vue";
export default {
  props: {
    store: Object,
    position: Object,
    userPosition: Object,
    isStreetView: Boolean,
    showBigName: Boolean,
    showStoreImage: Boolean,
    showOpeningTimesSubline: Boolean,
    showAddressSubline: Boolean,
    showDistanceSubline: Boolean,
    showActionButtons: Boolean,
    showAddress: Boolean,
    showProductTags: Boolean,
    showStreetViewButton: Boolean,
    showOpeningTimes: Boolean,
    showAdditionalImages: Boolean,
    deHighlightStore: Function,
  },
  name: "StoreInfoDetailed",
  data() {
    return {
      showTimes: false,
      maxHeight: 0,
      currentHeight: 0,
    };
  },
  components: {
    StoreInfoDistance: StoreInfoDistance,
    StoreInfoAddressSubline: StoreInfoAddressSubline,
    StoreInfoOpeningTimesSubline: StoreInfoOpeningTimesSubline,
    StoreInfoStoreLogo: StoreInfoStoreLogo,
  },
  methods: {
    addStreetView() {
      EventBus.$emit("add_streetview_panorama", this.position);
    },
    esacpeStreetView() {
      EventBus.$emit("escape_street_view");
    },
    calculateBoxHeight() {
      let box = this.$refs["details-box"].getBoundingClientRect();
      let innerBoxHeight = this.$refs["inner-box"].offsetHeight;
      let innerHeight = window.innerHeight;
      this.currentHeight = innerBoxHeight;
      this.maxHeight = innerHeight - box.top - 16;
    },
    openContactModal() {
      EventBus.$emit("open_contact_modal");
    },
  },
  computed: {
    distance() {
      let distance = google.maps.geometry.spherical.computeDistanceBetween(
        this.position,
        this.userPosition
      );
      return (distance / 1000).toFixed(1);
    },
  },
};
</script>

<style scoped>
.detail-store-info-close-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 999px;
  background-color: var(--rsf-2022-color-white);
  cursor: pointer;
}
</style>
