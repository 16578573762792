<template>
  <div style="height: 47px; aspect-ratio: 1/1; background-color: #d9d9d9; border-radius: 8px;"></div>
</template>

<script>
export default {
  name: 'StoreInfoStoreLogo',
  props: {
    store: Object
  }
};
</script>

<style scoped></style>