export function customInfoWindowConstruction(store, userPosition) {
  return `<div id="custom-info-window" style="max-width: 290px; width: 290px; box-shadow: 0 12.5px 10px 0 rgba(37, 44, 59, 0.07); border-radius: 8px; transform: translateY(calc(-50% - 2.7rem)) translateX(3rem);
" class="bg-color-white col-12 drop-shadow relative store-details-window">
  <div class="bg-color-white"
    style="border-radius: 5px; width: 2rem; height: 2rem; transform: translateY(-50%) rotate(45deg); position: absolute; top: 0; left: -0.8rem; top: 50%; z-index: -1">
  </div>
  ${
    store.dealerDetails.images.storeThumbnail !== ""
      ? `<img 
      src="${store.dealerDetails.images.storeThumbnail}" 
      alt="Store thumbnail" 
      style="
        min-height: 170px;
        max-height: 170px;
        object-fit: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      " 
      class="cover col-12 bg-color-black max-width-3" 
    />`
      : ""
  }
  <div class="pb2 pt1">
    <p class="open-sans-bold h5 color-black px2">${store.dealerDetails.dealerName}</p>
    <p class="open-sans-regular h7 color-13 px2">${store.dealerDetails.address1}${store.dealerDetails.address2 ? " " + store.dealerDetails.address2 : ""}, ${store.dealerDetails.postal} ${store.dealerDetails.city}</p>
    <div class="flex items-center gap1 px2">
      <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <rect y="0.428955" width="11" height="14" fill="url(#pattern0_3434_705)" />
        <defs>
          <pattern id="pattern0_3434_705" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlink:href="#image0_3434_705" transform="matrix(0.043771 0 0 0.0342556 -0.222222 -0.00461133)" />
          </pattern>
          <image id="image0_3434_705" width="33" height="32"
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAgCAYAAACcuBHKAAADF0lEQVRYCe1WTUtyURDut2S1UQoylb4XgtCykrCdEEYFFfSlGGERFBq1cVcoFm1ctCzdtWoT6SIoCKI2RVAQogVS4bw8A1fOteO9V99NQQMH5z7nzMzTnJkzNdAPkIYfwIF+J4lMJkMbGxs0ODhIbW1tZLfbaWpqinZ3d+nm5qauxBrOxP39PQ0NDVFjYyMHHx0dpVAoxGtkZIRaW1vJZDLR1tYWfX5+1kTGEInr62uyWq3kcDjo5OREGqBUKtHe3h41NzfTwMAA3d3dSc/JQF0SDw8P1NHRQU6nk56enmQ+VNjV1RX19vby+WKxqNqr9qFLwu12U1dXFz0/P1fz8Q2/vLzkq9nc3Py2JwM0SZyennINpNNple3b2xtFo1EaHx/nBR2YKNvb22x7cXEhwlJdk8TCwgJ1dnaqDF9fX6mnp4cDYA8LxQoMe4rkcjnG5+fnFajqryaJ/v5+WlpaUhlHIhF2nkqlyjh0EMGeKC6Xi3CdeqJJwmKxcMuJTiYmJrhL0A2KQG9vbyfsieL3+7moRUyma5KA49XVVZXd5OQkP1CVJPBoYU8UFKbZbBYhqa5JYnh4mLxer8pQdh3Hx8fS6xgbG6O+vj6VvexDk8Tc3Nw3Jyg+tCxqAEVps9lYryxMBMN7UXlFNZPAPECw29tblW2hUCi3KK4ALQpMlMfHR7bd398XYamumYmXlxd+hldWVqTGWuD6+jo1NTWp2rbaeU0SMFpcXCR0SeVfWs0h8Hw+zzbT09Nax8p7uiQwvHAlOzs7ZSM9JRwOsw3miBHRJQEnwWCQZ0E2m9X1eXZ2xgSMZgEODZHANMQU7e7upvf396pE0DkY+Zi6eLaNiiEScIb/mlpaWmhmZobEh0oJ9PHxQR6Phwv5/PxcgQ39GiYBb8lkklMdCARURL6+vsjn8/He0dGRocDioZpIwFB5OzAXkBGs2dlZJnB4eCj6NqzXTAKe8TihY5aXl2ltbY31RCJhOGjlwbpIwAmGE4hgxePxSr81fddNAlFisRgdHBzUFFB2+L9IyBzWg/2RULL2lwklE/8AlKk/jtRaHxYAAAAASUVORK5CYII=" />
        </defs>
      </svg>
      <p class="open-sans-regular color-13 h7">${Number(store.distance).toFixed(1)} km away</p>
    </div>
    <div id="info-window-horizontal-overflow-container" class="flex gap1 items-center mt2 px2 overflow-x-scroll no-scrollbar">
    ${
      store.dealerDetails.websiteURL != ""
        ? `<a href="${store.dealerDetails.websiteURL}" rel="noreferrer noopener" target="_blank" class="rsf-btn btn-small rsf-btn-ghost color-1 h7 flex items-center gap1"
        style="padding: 0.5rem 0.75rem; font-size: 0.75rem">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.4398 12.9077L11.8398 13.6499" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M1.92871 12.6801L3.51996 13.1468" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M13.4278 3.73584L11.6401 3.13751L11.3535 3.04157" stroke="#1867FF" stroke-width="0.832"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M1.91992 4.26941L3.83992 3.76854" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M13.4351 3.73584L13.4401 6.18725" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M13.4351 10.9882L13.4401 12.9082" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M1.91992 4.26941L1.91992 6.50941" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M1.92871 10.6689L1.92871 12.6823" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M3.49121 11.3363L9.25121 12.9072L11.8694 11.8599" stroke="#1867FF" stroke-width="0.832"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3.49121 5.57739L9.25121 4.2683L11.8694 5.05376" stroke="#1867FF" stroke-width="0.832"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3.49121 8.45691H9.25121H11.8692" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M9.25098 4.53052L9.25098 12.6469" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M11.8735 5.05286L11.8735 11.8601" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M3.49121 5.57739L3.49121 11.3374" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M6.10986 8.71802V11.8598" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        Website
      </a>`
        : ""
    }
    ${
      store.dealerDetails.phoneNumber != ""
        ? `<a href="tel:${store.dealerDetails.phoneNumber}" class="rsf-btn btn-small rsf-btn-ghost color-1 h7 flex items-center gap1"
        style="padding: 0.5rem 0.75rem; font-size: 0.75rem">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.4398 12.9077L11.8398 13.6499" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M1.92871 12.6801L3.51996 13.1468" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M13.4278 3.73584L11.6401 3.13751L11.3535 3.04157" stroke="#1867FF" stroke-width="0.832"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M1.91992 4.26941L3.83992 3.76854" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M13.4351 3.73584L13.4401 6.18725" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M13.4351 10.9882L13.4401 12.9082" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M1.91992 4.26941L1.91992 6.50941" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M1.92871 10.6689L1.92871 12.6823" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M3.49121 11.3363L9.25121 12.9072L11.8694 11.8599" stroke="#1867FF" stroke-width="0.832"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3.49121 5.57739L9.25121 4.2683L11.8694 5.05376" stroke="#1867FF" stroke-width="0.832"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3.49121 8.45691H9.25121H11.8692" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M9.25098 4.53052L9.25098 12.6469" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M11.8735 5.05286L11.8735 11.8601" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M3.49121 5.57739L3.49121 11.3374" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M6.10986 8.71802V11.8598" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        Phone
      </a>`
        : ""
    }
        <button class="rsf-btn btn-small rsf-btn-ghost color-1 h7 flex items-center gap1"
        style="padding: 0.5rem 0.75rem; font-size: 0.75rem">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.4398 12.9077L11.8398 13.6499" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M1.92871 12.6801L3.51996 13.1468" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M13.4278 3.73584L11.6401 3.13751L11.3535 3.04157" stroke="#1867FF" stroke-width="0.832"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M1.91992 4.26941L3.83992 3.76854" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M13.4351 3.73584L13.4401 6.18725" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M13.4351 10.9882L13.4401 12.9082" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M1.91992 4.26941L1.91992 6.50941" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M1.92871 10.6689L1.92871 12.6823" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M3.49121 11.3363L9.25121 12.9072L11.8694 11.8599" stroke="#1867FF" stroke-width="0.832"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3.49121 5.57739L9.25121 4.2683L11.8694 5.05376" stroke="#1867FF" stroke-width="0.832"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3.49121 8.45691H9.25121H11.8692" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M9.25098 4.53052L9.25098 12.6469" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M11.8735 5.05286L11.8735 11.8601" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M3.49121 5.57739L3.49121 11.3374" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M6.10986 8.71802V11.8598" stroke="#1867FF" stroke-width="0.832" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        Contact
      </button>
    </div>
    <div class="h1px col-12 bg-color-12 my2 px2 border-box" style="background-clip: content-box"></div>
    <div class="flex gap1 items-center overflow-x-scroll no-scrollbar px2">
      <a href="https://www.google.com/maps/dir/?api=1&origin=${userPosition.lat}%2C${userPosition.lng}&destination=${store.mapCoordsLatitude}%2C${store.mapCoordsLongitude}&travelmode=car" class="rsf-btn rsf-btn-std bg-color-1 color-white open-sans-regular h7"
        style="font-size: 0.75rem; padding: 0.75rem 1rem">Get directions
      </a>
      <div id="streetview-btn" data-lat="${store.mapCoordsLatitude}" data-lng="${store.mapCoordsLongitude}" class="gmiw-test rsf-btn rsf-btn-std bg-color-1 color-white open-sans-regular h7"
        style="font-size: 0.75rem; padding: 0.75rem 1rem">
        Street view
      </div>
    </div>
  </div>
</div>`;
}
