<template>
  <div class="flex items-center gap1 no-shrink">
    <p class="h6 open-sans-regular color-3">Open</p>
    <div class="bg-color-12" style="width: 1px; height: 16px;"></div>
    <p class="h6 open-sans-regular color-13">Closes at 6pm</p>
    <div class="bg-color-12" style="width: 1px; height: 16px;"></div>
  </div>
</template>

<script>
export default {
  name: 'StoreInfoOpeningTimesSubline',
  props: {
    store: Object
  }
};
</script>

<style scoped></style>