<template>
  <div
    :class="`bg-color-white p2 ${
      index == selectedStore ? 'active-outline' : ''
    }`"
    style="border-radius: 8px"
  >
    <p class="open-sans-bold color-black h5" style="margin-bottom: 0.25rem">
      {{ store.name }}
    </p>
    <p class="open-sans-regular h7 color-14">
      {{ store.dealerDetails.address1
      }}{{
        store.dealerDetails.address2 ? " " + store.dealerDetails.address2 : ""
      }}, {{ store.dealerDetails.postal }} {{ store.dealerDetails.city }}
    </p>
    <div class="h1px col-12 bg-color-12" style="margin: 0.75rem 0"></div>
    <p class="flex items-center" style="gap: 4px">
      <svg width="9" height="13" viewBox="0 0 9 13" fill="none">
        <path
          d="M8.5 4.60417C8.5 5.75295 8.04112 7.08671 7.24657 8.49422C6.53625 9.75251 5.57637 11.0369 4.5 12.2532C3.42363 11.0369 2.46375 9.75251 1.75343 8.49422C0.958882 7.08671 0.5 5.75295 0.5 4.60417C0.5 2.32665 2.30159 0.5 4.5 0.5C6.69841 0.5 8.5 2.32665 8.5 4.60417Z"
          stroke="#1867FF"
        />
        <circle cx="4.5" cy="4.5" r="2" stroke="#1867FF" />
      </svg>
      <span class="h7 open-sans-regular color-1">{{ distance }}km away</span>
    </p>
  </div>
</template>

<script>
import StoreInfoOpeningTimesSubline from "./StoreInfoOpeningTimesSubline.vue";
import StoreInfoAddressSubline from "./StoreInfoAddressSubline.vue";
import StoreInfoStoreLogo from "./StoreInfoStoreLogo.vue";
import StoreInfoDistance from "./StoreInfoDistance.vue";
export default {
  props: {
    store: Object,
    position: Object,
    userPosition: Object,
    showStoreImage: Boolean,
    showArrow: Boolean,
    showOpeningTimes: Boolean,
    showAddress: Boolean,
    showDistance: Boolean,
    index: Number,
    selectedStore: Number,
    hasExitStreetViewBtn: Boolean,
  },
  name: "StoreInfoSmall",
  data() {
    return {
      expandOpeningTimes: false,
      maxHeight: 0,
      currentHeight: 0,
    };
  },
  components: {
    StoreInfoOpeningTimesSubline: StoreInfoOpeningTimesSubline,
    StoreInfoAddressSubline: StoreInfoAddressSubline,
    StoreInfoStoreLogo: StoreInfoStoreLogo,
    StoreInfoDistance: StoreInfoDistance,
  },
  computed: {
    distance() {
      let distance = google.maps.geometry.spherical.computeDistanceBetween(
        this.position,
        this.userPosition
      );
      return (distance / 1000).toFixed(1);
    },
  },
};
</script>

<style scoped>
.active-outline {
  box-shadow: 0 0 0 1px var(--rsf-2022-color-1);
}
</style>
