<template>
  <p class="h6 open-sans-regular color-13 no-shrink">{{ distance }} {{ $t('storefinder_unit_kilometers') }} {{$t('storefinder_store_away') }}</p>
</template>

<script>
export default {
  name: 'StoreInfoDistance',
  props: {
    position: Object, 
    userPosition: Object
  },
  computed: {
    distance() {
      let distance = google.maps.geometry.spherical.computeDistanceBetween(this.position, this.userPosition);
      return (distance / 1000).toFixed(1);
    }
  }
};
</script>

<style scoped></style>