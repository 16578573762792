export function customInfoWindowLogic() {
  console.log("init gmiw logic!");
  let myBtn = document.getElementsByClassName("gmiw-test");
  let btnId = document.getElementById("mytest-btn");
  console.log("myBtn");
  console.log(myBtn);
  console.log(myBtn.length);
  console.log(btnId);

  //TODO find more stabel solution than a timeout
  setTimeout(() => {
    let infoWindowScrollContainer = document.getElementById(
      "info-window-horizontal-overflow-container"
    );
    let streetViewBtn = document.getElementById("streetview-btn");

    infoWindowScrollContainer.addEventListener("touchstart", (e) => {
      e.stopPropagation();
    });
    infoWindowScrollContainer.addEventListener("scroll", (e) => {
      e.stopPropagation();
    });
    streetViewBtn.addEventListener("click", () => {
      console.log("clicked on streetview");
      console.log(Number(streetViewBtn.getAttribute("data-lat")));
      console.log(Number(streetViewBtn.getAttribute("data-lng")));
      window.vueApp.$children[0].$children[0].$children[0].addStreetViewPanorama(
        {
          lat: Number(streetViewBtn.getAttribute("data-lat")),
          lng: Number(streetViewBtn.getAttribute("data-lng")),
        }
      );
      window.vueApp.$children[0].$children[0].isStreetView = true;
      console.log(window.vueApp.$children[0].$children[0]);
      // console.log(window.vueApp.addStreetViewPanorama());
    });
  }, 100);
}
