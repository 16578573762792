<template>
  <div class="map-flex flex-grow overflow-hidden">
    <!-- desktop search bar & results -->
    <div
      class="hide lg-flex lg-flex-column gap3 col-12 max-width-2 border-box bg-color-11"
      style="max-width: 420px"
    >
      <div class="vertical-overflow">
        <!-- top part -->
        <div
          class="flex flex-column gap2 p3 bg-color-11"
          style="position: sticky; top: 0; z-index: 2"
        >
          <h2 class="color-black open-sans-bold h2">
            {{ $t("storefinder_form_heading") }}
          </h2>
          <div class="relative">
            <svg
              class="bg-color-white px1"
              style="
                position: absolute;
                z-index: 3;
                top: 50%;
                right: 0.5rem;
                transform: translateY(-50%);
              "
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                width="2.2345"
                height="4.80648"
                rx="1.11725"
                transform="matrix(-0.711146 0.703044 -0.711146 -0.703044 22.4482 20.8848)"
                fill="#555D65"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.99 19.0461C15.5408 19.0461 19.2299 15.399 19.2299 10.9C19.2299 6.40104 15.5408 2.75391 10.99 2.75391C6.43916 2.75391 2.75 6.40104 2.75 10.9C2.75 15.399 6.43916 19.0461 10.99 19.0461Z"
                stroke="#555D65"
                stroke-width="1.5"
              />
            </svg>

            <input
              ref="address-input"
              type="text"
              class="rsf-input"
              v-model="address"
              :placeholder="$t('storefinder_placeholder_address')"
            />
          </div>
          <div class="rsf-select-wrapper relative">
            <svg
              class="svg-color-14"
              :style="`transform: rotate(180deg) translateY(50%)`"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M17 15L12 10L7 15"
                stroke=""
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <select
              @change="getStoresByAddress"
              v-model="radius"
              class="rsf-select"
              :placeholder="$t('storefinder_placeholder_perimeter')"
            >
              <option
                v-for="(range, index) in perimeterOptions"
                :key="index"
                :value="range.km"
              >
                {{ range.km }} {{ $t("storefinder_unit_kilometers") }}
              </option>
            </select>
          </div>
        </div>

        <div
          v-if="markers.length > 0"
          class="flex flex-column gap2 px3 pb3 mt1"
        >
          <!-- TODO?: pagination when there are many stores -->
          <div
            v-for="(store, index) in markers"
            :key="index"
            @click="
              highlightStore(
                {
                  lat: Number(store.mapCoordsLatitude),
                  lng: Number(store.mapCoordsLongitude),
                },
                index,
                store
              )
            "
          >
            <StoreInfoSmall
              :position="{
                lat: Number(store.mapCoordsLatitude),
                lng: Number(store.mapCoordsLongitude),
              }"
              :userPosition="userPosition"
              :showArrow="true"
              :showStoreImage="true"
              :showDistance="true"
              :showOpeningTimes="false"
              :index="index"
              :selectedStore="selectedStore"
              :store="store"
            />
          </div>
        </div>
        <div
          class="flex flex-column justify-center px3 border-box"
          v-if="markers.length == 0 && searchesMade == 0"
        >
          <p class="h2 open-sans-bold center">No search results.</p>
          <p class="h4 open-sans-regular center">
            Type in your preferences and look for stores
          </p>
        </div>
        <div
          class="flex flex-column justify-center px3 border-box"
          v-if="markers.length == 0 && searchesMade > 0"
        >
          <p class="h2 open-sans-bold center">No search results.</p>
          <p class="h4 open-sans-regular center">for your input.</p>
        </div>
      </div>

      <!-- mobile input part -->
      <div class="lg-hide block p2 bg-color-white">
        <div class="relative">
          <input
            @click="expandMobileInputs = true"
            ref="address-input-mobile"
            type="text"
            class="rsf-input"
            v-model="address"
            placeholder="Address"
          />
          <svg
            @click="
              address = '';
              $refs['address-input'].focus();
            "
            v-show="address != ''"
            class="pointer"
            style="
              position: absolute;
              top: 50%;
              right: 1rem;
              transform: translateY(-50%);
            "
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clip-path="url(#clip0_1922_48204)">
              <rect
                width="19.6867"
                height="2"
                rx="1"
                transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.15625 1.58594)"
                fill="black"
              />
              <rect
                x="15.4902"
                y="1.58594"
                width="19.6867"
                height="2"
                rx="1"
                transform="rotate(135 15.4902 1.58594)"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_1922_48204">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="matrix(-1 0 0 1 16 0)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div v-show="expandMobileInputs" class="flex flex-column gap2 mt2">
          <p class="open-sans-bold p color-black">
            {{ $t("storefinder_label_perimeter") }}
          </p>
          <div
            class="flex gap2 items-center color-12"
            style="
              border: 1px solid;
              border-radius: 999px;
              height: 50px;
              padding: 0 1.5rem;
            "
          >
            <input
              @change="getStoresByAddress(), (expandMobileInputs = false)"
              v-model="radius"
              type="range"
              min="50"
              max="500"
              value="50"
              step="50"
              class="slider col-12"
            />
            <p class="open-sans-regular h5 color-black nowrap">
              {{ radius }} {{ $t("storefinder_unit_kilometers") }}
            </p>
          </div>
          <button
            @click="getStoresByAddress(), (expandMobileInputs = false)"
            v-if="googleMapsLoaded"
            :class="`rsf-btn rsf-btn-primary bg-color-1 color-white mx-auto ${
              address == '' ? 'btn-inactive' : ''
            }`"
          >
            {{ $t("storefinder_search") }}
          </button>
        </div>
      </div>
    </div>

    <div class="relative col-12 flex-grow">
      <GoogleMaps
        :zoom="8"
        :center="{ lat: 52.52, lng: 13.405 }"
        :userPosition="userPosition"
      ></GoogleMaps>

      <!-- StoreItem for Streetview -->
      <div
        v-if="selectedStore >= 0 && isStreetView"
        style="
          position: absolute;
          bottom: 2rem;
          left: 0.7rem;
          z-index: 2;
          max-width: 400px;
        "
      >
        <StoreInfoSmall
          :position="{
            lat: Number(markers[selectedStore].mapCoordsLatitude),
            lng: Number(markers[selectedStore].mapCoordsLongitude),
          }"
          :userPosition="userPosition"
          :showArrow="true"
          :showStoreImage="true"
          :showDistance="true"
          :showOpeningTimes="false"
          :index="index"
          :selectedStore="selectedStore"
          :store="markers[selectedStore]"
          :hasExitStreetViewBtn:="true"
        />
      </div>

      <!-- Loading -->
      <div
        v-show="isLoading"
        class="absolute p1 bg-color-white"
        style="top: 0.7rem; right: 0.7rem; border-radius: 6px"
      >
        <div class="maps-loader"></div>
      </div>

      <!-- No Address Found -->
      <div
        v-show="addressNotFound"
        class="absolute col-12 h100 lg-p4 p2 border-box"
        :style="`top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 99; display: grid; place-items: center; background-color: rgba(0,0,0,0.25);`"
      >
        <div
          class="bg-color-white col-12 h100 max-width-3 lg-p4 p2 border-box"
          style="border-radius: 8px; display: grid; place-items: center"
        >
          <p class="h2 color-black open-sans-bold">
            {{ $t("storefinder_address_not_found") }}
          </p>
          <div
            @click="addressNotFound = false"
            class="rsf-btn rsf-btn-primary bg-color-1 color-white mx-auto pointer"
          >
            Ok
          </div>
        </div>
      </div>

      <!-- No Stores found -->
      <div
        v-show="markers.length <= 0 && searchesMade > 0 && !isLoading"
        class="absolute col-12 h100 lg-p4 p2 border-box"
        :style="`top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 99; display: grid; place-items: center; background-color: rgba(0,0,0,0.25);`"
      >
        <div
          class="bg-color-white col-12 h100 max-width-3 lg-p4 p2 border-box"
          style="border-radius: 8px; display: grid; place-items: center"
        >
          <p class="h2 color-black open-sans-bold">
            {{ $t("storefinder_no_stores_found") }}
          </p>
        </div>
      </div>

      <!-- Api Error -->
      <div
        v-show="apiError && !isLoading"
        class="absolute col-12 h100 lg-p4 p2 border-box"
        :style="`top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 99; display: grid; place-items: center; background-color: rgba(0,0,0,0.25);`"
      >
        <div
          class="bg-color-white col-12 h100 max-width-3 lg-p4 p2 border-box"
          style="border-radius: 8px; display: grid; place-items: center"
        >
          <p class="h2 color-black open-sans-bold">
            {{ $t("storefinder_api_error") }}
          </p>
          <div
            @click="apiError = false"
            class="rsf-btn rsf-btn-primary bg-color-1 color-white mx-auto pointer"
          >
            Ok
          </div>
        </div>
      </div>
    </div>

    <!-- mobile store listing -->
    <div
      ref="draggable"
      @mousedown="startDrag"
      @mousemove="doDrag"
      @touchstart="startDrag"
      @touchmove="doTouchDrag"
      class="lg-hide bg-color-white relative"
      :style="`height: 3rem; transform: translateY(${y}px); border-radius: 8px; ${
        dragging ? '' : 'transition: 0.4s ease;'
      }`"
    >
      <div
        class="col-12 absolute bg-color-white flex flex-column"
        :style="`height: 100vh; border-top-left-radius: 8px; border-top-right-radius: 8px; transition: .3s ease-in-out; border-radius: 8px; ${
          expandMobileResults
            ? 'transform: translateY(calc(-100% + 3rem))'
            : 'transform: translateY(0)'
        }`"
      >
        <div
          class="block mx-auto bg-color-13 mt2"
          style="height: 4px; border-radius: 999px; width: 2rem"
        ></div>
        <div
          style="height: 3rem; place-items: center; display: grid"
          class="border-box pointer relative no-shrink grid"
        ></div>
        <div class="h1px col-12 bg-color-12"></div>
        <div
          v-if="markers.length > 0"
          class="flex-grow overflow-y-scroll no-scrollbar p2 border-box"
        >
          <div
            v-for="(store, index) in markers"
            :key="index"
            @click="
              highlightStore(
                {
                  lat: Number(store.mapCoordsLatitude),
                  lng: Number(store.mapCoordsLongitude),
                },
                index
              )
            "
          >
            <StoreInfoSmall
              :store="store"
              :position="{
                lat: Number(store.mapCoordsLatitude),
                lng: Number(store.mapCoordsLongitude),
              }"
              :userPosition="userPosition"
              :showArrow="true"
              :showStoreImage="true"
              :showAddress="false"
              :showOpeningTimes="true"
              :showDistance="true"
              :index="index"
              :selectedStore="selectedStore"
            />
          </div>
        </div>
      </div>
    </div>

    <Storefinder_Modal :ref="'contact-modal'">
      <ContactForm
        v-if="selectedStore >= 0"
        :store="markers[selectedStore]"
        :userPosition="userPosition"
      />
    </Storefinder_Modal>

    <Storefinder_Modal :ref="'mobile-details-modal'"> </Storefinder_Modal>
  </div>
</template>

<script>
import axios from "axios";
import { EventBus } from "@/event-bus";
import GoogleMaps from "./GoogleMaps.vue";
import Storefinder_Modal from "./Storefinder_Modal.vue";
import ContactForm from "./ContactForm.vue";
import StoreInfoSmall from "./StoreInfoSmall.vue";
import StoreInfoDetailed from "./StoreInfoDetailed.vue";

export default {
  name: "Storefinder",
  props: {
    siteLanguage: String,
  },
  components: {
    GoogleMaps: GoogleMaps,
    Storefinder_Modal: Storefinder_Modal,
    ContactForm: ContactForm,
    StoreInfoSmall: StoreInfoSmall,
    StoreInfoDetailed: StoreInfoDetailed,
  },
  data() {
    return {
      googleMapsLoaded: false,
      coords: {},
      userPosition: {},
      address: "Am Hillernsen Hamm 51",
      country: { isocode2: "il" },
      markers: [],
      radius: 100,
      isLoading: false,
      addressNotFound: false,
      selectedStore: -1,
      searchesMade: 0,
      expandMobileResults: false,
      expandMobileInputs: false,
      isStreetView: false,
      apiError: false,
      perimeterOptions: [
        { km: 50, miles: 50 },
        { km: 75, miles: 60 },
        { km: 100, miles: 70 },
        { km: 125, miles: 80 },
        { km: 150, miles: 90 },
        { km: 200, miles: 50 },
        { km: 250, miles: 50 },
        { km: 500, miles: 50 },
      ],

      dragging: false,
      x: 0,
      y: 0,
      initialDragOffset: 0,
      expandMobileStoreList: false,
    };
  },
  created() {
    EventBus.$on("google_maps_loaded", () => {
      this.googleMapsLoaded = true;
    });
    EventBus.$on("select_store", (position) => {
      this.selectStore(position);
    });
    EventBus.$on("add_streetview_panorama", () => {
      this.isStreetView = true;
    });
    EventBus.$on("escape_street_view", () => {
      this.isStreetView = false;
    });
    EventBus.$on("open_contact_modal", () => {
      this.$refs["contact-modal"].open();
    });
  },
  mounted() {
    // this.getStoresByAddress();
    window.addEventListener("mouseup", this.stopDrag);
    window.addEventListener("touchend", this.stopDrag);
    document.addEventListener("keydown", (e) => {
      if (e.key != "Enter") {
        return;
      }
      if (
        (this.$refs["address-input"] === document.activeElement ||
          this.$refs["address-input-mobile"] === document.activeElement) &&
        this.address != ""
      ) {
        this.getStoresByAddress();
      }
    });
  },
  methods: {
    startDrag(event) {
      this.dragging = true;
      // if (this.event.touches == undefined) {
      //   return;
      // }
      if (event.touches.length <= 0) {
        return;
      }
      this.initialDragOffset =
        event.touches[0].clientY -
        document.documentElement.clientHeight -
        this.y;
    },
    stopDrag() {
      this.dragging = false;
      this.initialDragOffset = 0;
      if (this.y < -(window.innerHeight - 200) && this.expandMobileStoreList) {
        this.y = 0;
        this.expandMobileStoreList = false;
        console.log("EMSL false");
        return;
      }
      if (this.y < -200 && !this.expandMobileStoreList) {
        this.y = -(window.innerHeight - 90);
        this.expandMobileStoreList = true;
        console.log("EMSL true");
        return;
      }
      this.y = 0;
      this.expandMobileStoreList = false;
      console.log("EMSL false");
    },
    doDrag(event) {
      if (this.dragging) {
        this.y = event.clientY - document.clientHeight;
      }
    },
    doTouchDrag(event) {
      event.preventDefault();
      if (this.dragging && event.touches.length > 0) {
        this.y =
          event.touches[0].clientY -
          document.documentElement.clientHeight -
          this.initialDragOffset;
      }
    },
    getStoresByCountry() {
      this.isLoading = true;
      this.apiError = false;
      let that = this;
      let url =
        process.env.VUE_APP_RSF_API +
        "/public-api/storefinder/getstoresbycountry/json";
      axios
        .post(url, { isocode2: that.country.isocode2 })
        .then(function (response) {
          that.markers = response.data.mapPoints;
          EventBus.$emit("load_markers", that.markers);
          that.isLoading = false;
        })
        .catch(function () {
          that.isLoading = false;
          that.apiError;
        });
    },
    getStoresByAddress() {
      if (this.address == "") {
        return;
      }
      this.markers = [];
      this.userPosition = {};
      this.selectedStore = -1;
      this.isLoading = true;
      this.addressNotFound = false;
      this.isStreetView = false;
      this.apiError = false;
      let that = this;
      let url =
        process.env.VUE_APP_RSF_API +
        "/public-api/storefinder/getstoresbyaddress/json";
      axios
        .post(url, { address: that.address, radius: Number(this.radius) })
        .then(function (response) {
          that.isLoading = false;
          if (!response.data.addressFound) {
            that.addressNotFound = true;
            return;
          }
          let position = {
            lat: response.data.mapsViewport.centerLatitude,
            lng: response.data.mapsViewport.centerLongitude,
            zoom: response.data.mapsViewport.zoomLevel,
          };
          that.userPosition = position;
          that.markers = response.data.mapPoints;
          EventBus.$emit("load_markers", that.markers);
          EventBus.$emit("draw_circle", {
            position: position,
            radius: that.radius,
          });
          EventBus.$emit("go_to_point", position);
          that.searchesMade++;
        })
        .catch(function () {
          that.apiError = true;
        });
    },
    // getStoresByCoords() {
    //   this.isLoading = true;
    //   this.apiError = false;
    //   let that = this;
    //   let url =
    //     process.env.VUE_APP_RSF_API +
    //     "/public-api/storefinder/getstoresbycoordinates/json";
    //   axios
    //     .post(url, that.coords)
    //     .then(function (response) {
    //       let position = {
    //         lat: response.data.mapsViewport.centerLatitude,
    //         lng: response.data.mapsViewport.centerLongitude,
    //         zoom: response.data.mapsViewport.zoomLevel,
    //       };
    //       that.markers = response.data.mapPoints;
    //       that.stores = response.data.mapPoints;
    //       EventBus.$emit("load_markers", that.markers);
    //       EventBus.$emit("go_to_point", position);
    //       that.isLoading = false;
    //     })
    //     .catch(function () {
    //       that.isLoading = false;
    //       that.apiError = true;
    //     });
    // },
    clearAllMarkers() {
      EventBus.$emit("clear_all_markers");
    },
    clearSingleMarker() {
      EventBus.$emit("clear_single_marker", 0);
    },
    highlightStore(position, index, store) {
      this.selectedStore = index;
      EventBus.$emit("highlight_marker", {
        position: position,
        store: store,
      });
    },
    highlightStoreMobile(position, index) {
      this.expandMobileResults = false;
      this.selectedStore = index;
      this.$refs["mobile-details-modal"].open();
      EventBus.$emit("highlight_marker", position);
    },
    deHighlightStore() {
      this.selectedStore = -1;
      EventBus.$emit("dehighlight_marker");
    },
    selectStore(position) {
      for (let i = 0; i < this.markers.length; i++) {
        if (
          Number(this.markers[i].mapCoordsLatitude) == position.lat &&
          Number(this.markers[i].mapCoordsLongitude) == position.lng
        ) {
          this.selectedStore = i;
          return;
        }
      }
    },
  },
};
</script>

<style scoped>
.vertical-overflow {
  overflow-y: auto;
  box-sizing: border-box;
  flex: 1 1 auto;
  height: 1px;
  cursor: pointer;
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 2px;
  /* Specified height */
  background: var(--rsf-2022-color-12);
  /* Grey background */
  outline: none;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  border-radius: 999px;
  /* Slider handle height */
  background: var(--rsf-2022-color-1);
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  border-radius: 999px;
  /* Slider handle height */
  background: var(--rsf-2022-color-1);
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.btn-inactive {
  pointer-events: none;
  opacity: 0.5;
}

.map-flex {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1024px) {
  .map-flex {
    flex-direction: column;
  }
}

/* HTML: <div class="loader"></div> */
.maps-loader {
  width: 16px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid var(--rsf-2022-color-1);
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }

  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }

  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}
</style>
