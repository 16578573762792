<template>
    <div class="flex items-center gap1 no-shrink">
      <svg class="no-shrink" width="12" height="19" viewBox="0 0 12 19" fill="none">
        <path
          d="M11.7772 6.4645C11.7772 9.71668 5.88858 18.6683 5.88858 18.6683C5.88858 18.6683 0 9.71668 0 6.4645C0 3.21233 2.63641 0.575928 5.88858 0.575928C9.14075 0.575928 11.7772 3.21233 11.7772 6.4645Z"
          fill="#1867FF" />
        <circle cx="5.88891" cy="6.20019" r="1.90576" fill="white" />
      </svg>
      <p class="h5 open-sans-regular no-shrink color-13">Neue Straße 1</p>
      <div class="bg-color-12 no-shrink" style="width: 1px; height: 16px;"></div>
      <p class="h5 open-sans-regula no-shrink color-13">102030 Berlin</p>
      <div class="bg-color-12 no-shrink" style="width: 1px; height: 16px;"></div>
    </div>
</template>

<script>
export default {
  name: 'StoreInfoAddressSubline',
  props: {
    store: Object
  }
};
</script>

<style scoped></style>